import React from "react"
import './SelectorNavRight.scss'
import AniLink from "gatsby-plugin-transition-link/AniLink";

function SelectorNavRight(props) {
    return (
        <AniLink swipe direction="left" to={props.linkToPage}>
            <div className='nav-right-container'>
                <div className='title'>Portfolio</div>
            </div>
        </AniLink>
    )
}
export default SelectorNavRight;
