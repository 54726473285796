import React, { Component } from "react"
import "./App.scss"
import '../global.scss'
import SelectorNavRight from './selector-nav/right/SelectorNavRight';
import SelectorNavLeft from './selector-nav/left/SelectorNavLeft';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import ThemeSelector from "./theme-selector/ThemeSelector";
import Particles from 'react-particles-js';

export default class App extends Component {


  constructor() {
    super();
  }
  render() {
    return (
      <div className="selector">
        <Particles className="particles"
          params={{
            particles: {
              color: {
                value: "$text_light"
              }
            }
          }}
        />
        <div className="mobile-not-implemented">
          <AniLink swipe direction="left" to="/portfolio/">
            <div className='title'>Portfolio</div>
          </AniLink>
        </div>
        <div className="tutoring-slider">
          <SelectorNavLeft linkToPage="/tutoring/" displayText="Tutoring"></SelectorNavLeft>
        </div>
        <div className="center">
          <ThemeSelector></ThemeSelector>
          <div className="main-text">
            <div className="face-pic"></div>
            <div className="title">
              Hi, my name is Ken
        </div>
            <div className="subtitle">
              I am a software engineer
        </div>
          </div>
        </div>
        <div className="portfolio-slider">
          <SelectorNavRight linkToPage="/portfolio/" displayText="Portfolio"></SelectorNavRight>
        </div>
      </div>
    )
  }
}

