import React, { Component } from "react";
import { CirclePicker } from 'react-color';
import { FaPalette } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';

import { darkTheme, lightTheme, themeVariations } from "./themes";
import './ThemeSelector.scss';

export default class ThemeSelector extends Component {


    constructor() {
        super();
        this.state = {
            showColorPicker: false,
            selectedColor: undefined
        }
        this.toggleTheme = this.toggleTheme.bind(this);
        this.setFullTheme = this.setFullTheme.bind(this);
        this.getDarkThemeLocalStorage = this.getDarkThemeLocalStorage.bind(this);
        this.getColorLocalStorage = this.getColorLocalStorage.bind(this);
        this.setAccentColors = this.setAccentColors.bind(this);
        this.setInitialTheme = this.setInitialTheme.bind(this);
        this.showColorPicker = this.showColorPicker.bind(this);
        this.selectColor = this.selectColor.bind(this);


    }

    componentDidMount() {
        this.setState({
            selectedColor: this.getColorLocalStorage()
        })
        this.setFullTheme();
    }
    toggleTheme(event) {
        localStorage.setItem('dark-theme', event.target.checked)
        this.setFullTheme();
    }
    setFullTheme() {
        this.setInitialTheme(this.getDarkThemeLocalStorage() ? darkTheme : lightTheme)
        this.setAccentColors(this.getDarkThemeLocalStorage(), this.getColorLocalStorage())
    }

    getDarkThemeLocalStorage() {
        return !!localStorage.getItem('dark-theme') ? localStorage.getItem('dark-theme') === 'true' : 'true';
    }
    getColorLocalStorage() {
        return !!localStorage.getItem('color') ? localStorage.getItem('color') : '#FFC107'; 
    }

    setAccentColors(isDark, color) {
        const paletteToUse = themeVariations.find(theme => theme.id.toUpperCase() === color.toUpperCase());
        if (paletteToUse) {
            this.setState({
                selectedColor: color
            })
            localStorage.setItem('color', color)
            document.documentElement.style.setProperty('--accent_1', paletteToUse.accent_1);
            document.documentElement.style.setProperty('--accent_2', paletteToUse.accent_2);
            if (!isDark) {
                document.documentElement.style.setProperty('--background_nav', paletteToUse.background_nav);
                document.documentElement.style.setProperty('--background_dark', paletteToUse.background_dark);
                document.documentElement.style.setProperty('--background_accent', paletteToUse.background_accent);
                document.documentElement.style.setProperty('--button_primary', paletteToUse.button_primary);
            }
        }
    }

    setInitialTheme(theme) {
        if(typeof window !== 'undefined') {
            document.documentElement.style.setProperty('--background_dark', theme.background_dark);
            document.documentElement.style.setProperty('--background_light', theme.background_light);
            document.documentElement.style.setProperty('--background_nav', theme.background_nav);
            document.documentElement.style.setProperty('--background_accent', theme.background_accent);
            document.documentElement.style.setProperty('--button_primary', theme.button_primary);
            document.documentElement.style.setProperty('--text_dark', theme.text_dark);
            document.documentElement.style.setProperty('--text_light', theme.text_light);
            document.documentElement.style.setProperty('--icon_dark', theme.icon_dark);
            document.documentElement.style.setProperty('--portfolio_image', theme.portfolio_image);
            document.documentElement.style.setProperty('--tutoring_image', theme.tutoring_image);
        }
    }

    showColorPicker() {
        this.setState({
            showColorPicker: !this.state.showColorPicker
        })
    }
    selectColor(val) {
        this.setAccentColors(this.getDarkThemeLocalStorage(), val.hex)
    }

    render() {
        if(this.state.selectedColor == undefined ) {
            return ('')
        }
        const darktheme = this.getDarkThemeLocalStorage()
        var colorpicker = ('');
        if (this.state.showColorPicker) {
            colorpicker = (
                <div className="color-picker">
                    <IoMdClose className="close-btn" onClick={this.showColorPicker} />
                    <CirclePicker
                        color={this.state.selectedColor}
                        onChange={this.selectColor} />
                </div>

            )
        }
        return (
            <div className="theme-selector">
                <label className="switch">
                    <input onChange={this.toggleTheme} defaultChecked={darktheme} type="checkbox" />
                    <span className="slider round"></span>
                    <p className="picker-subtext">Darkmode</p>
                </label>
                <div className="color-picker-trigger" onClick={this.showColorPicker}>
                    <FaPalette />                    
                    <p className="picker-subtext">Color</p>
                </div>
                {colorpicker}
            </div>
        )
    }
}
