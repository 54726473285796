export const darkTheme = {
  //YELLOW ACCENT
  background_nav: "#222629",
  background_light: "#16191b",
  background_dark: "#1a1d1f",
  background_accent: "#2b2b35",
  accent_1: "#ffc107",
  accent_2: "#048b9a",
  button_primary: "#3f474d",
  text_dark: "#7e7d7d",
  text_light: "#fff",
  icon_dark: "#41484c",
  portfolio_image: 'url("/portfolio/portfolio_blurry.jpg")',
  tutoring_image: 'url( "/tutoring_dark.png")',
}

export const lightTheme = {
  // INDIGO
  background_nav: "#9FA8DA", // 200
  background_light: "white",
  background_dark: "#E8EAF6", // 50
  background_accent: "#C5CAE9", // 100
  accent_1: "#304FFE", // A700
  accent_2: "#3949AB", // 600
  button_primary: "#8C9EFF", // A100
  text_dark: "#616161",
  text_light: "#000000",
  icon_dark: "#616161",
  portfolio_image: 'url("/portfolio/portfolio_light_blurry.jpg")',
  tutoring_image: 'url( "/tutoring_light_blur.jpg")',
}

export const themeVariations = [
  {
    // RED
    id: "#F44336", // 500
    background_nav: "#EF9A9A", // 200
    background_dark: "#FFEBEE", // 50
    background_accent: "#FFCDD2", // 100
    accent_1: "#D50000", // A700
    accent_2: "#E53935", // 600
    button_primary: "#FF8A80", // A100
  },
  {
    // PINK
    accent_1: "#C51162",
    accent_2: "#D81B60",
    background_accent: "#F8BBD0",
    background_dark: "#FCE4EC",
    background_nav: "#F48FB1",
    button_primary: "#FF80AB",
    id: "#E91E63",
  },
  {
    // PURPLE
    accent_1: "#AA00FF",
    accent_2: "#8E24AA",
    background_accent: "#E1BEE7",
    background_dark: "#F3E5F5",
    background_nav: "#CE93D8",
    button_primary: "#EA80FC",
    id: "#9C27B0",
  },
  {
    // DEEP PURPLE
    accent_1: "#6200EA",
    accent_2: "#5E35B1",
    background_accent: "#D1C4E9",
    background_dark: "#EDE7F6",
    background_nav: "#B39DDB",
    button_primary: "#B388FF",
    id: "#673AB7",
  },
  {
    // INDIGO
    accent_1: "#304FFE",
    accent_2: "#3949AB",
    background_accent: "#C5CAE9",
    background_dark: "#E8EAF6",
    background_nav: "#9FA8DA",
    button_primary: "#8C9EFF",
    id: "#3F51B5",
  },
  {
    // BLUE
    accent_1: "#2962FF",
    accent_2: "#1E88E5",
    background_accent: "#BBDEFB",
    background_dark: "#E3F2FD",
    background_nav: "#90CAF9",
    button_primary: "#82B1FF",
    id: "#2196F3",
  },
  {
    // LIGHT BLUE
    accent_1: "#0091EA",
    accent_2: "#039BE5",
    background_accent: "#B3E5FC",
    background_dark: "#E1F5FE",
    background_nav: "#81D4FA",
    button_primary: "#80D8FF",
    id: "#03A9F4",
  },
  {
    // CYAN
    accent_1: "#00B8D4",
    accent_2: "#00ACC1",
    background_accent: "#B2EBF2",
    background_dark: "#E0F7FA",
    background_nav: "#80DEEA",
    button_primary: "#84FFFF",
    id: "#00BCD4",
  },
  {
    // TEAL
    accent_1: "#00BFA5",
    accent_2: "#00897B",
    background_accent: "#B2DFDB",
    background_dark: "#E0F2F1",
    background_nav: "#80CBC4",
    button_primary: "#A7FFEB",
    id: "#009688",
  },
  {
    // GREEN
    accent_1: "#00C853",
    accent_2: "#43A047",
    background_accent: "#C8E6C9",
    background_dark: "#E8F5E9",
    background_nav: "#A5D6A7",
    button_primary: "#B9F6CA",
    id: "#4CAF50",
  },
  {
    // LIGHT GREEN
    accent_1: "#64DD17",
    accent_2: "#7CB342",
    background_accent: "#DCEDC8",
    background_dark: "#F1F8E9",
    background_nav: "#C5E1A5",
    button_primary: "#CCFF90",
    id: "#8BC34A",
  },
  {
    // LIME
    accent_1: "#AEEA00",
    accent_2: "#C0CA33",
    background_accent: "#F0F4C3",
    background_dark: "#F9FBE7",
    background_nav: "#E6EE9C",
    button_primary: "#F4FF81",
    id: "#CDDC39",
  },
  {
    // YELLOW
    id: "#FFEB3B", // 500
    background_nav: "#FFF59D", // 200
    background_dark: "#FFFDE7", // 50
    background_accent: "#FFF9C4", // 100
    accent_1: "#f9a825", // A700
    accent_2: "#FDD835", // 600
    button_primary: "#FFFF8D", // A100
  },
  {
    // AMBER
    accent_1: "#ff8f00",
    accent_2: "#FFB300",
    background_accent: "#FFECB3",
    background_dark: "#FFF8E1",
    background_nav: "#FFE082",
    button_primary: "#FFE57F",
    id: "#FFC107",
  },
  {
    // ORANGE
    id: "#FF9800", // 500
    background_nav: "#FFCC80", // 200
    background_dark: "#FFF3E0", // 50
    background_accent: "#FFE0B2", // 100
    accent_1: "#FF6D00", // A700
    accent_2: "#FB8C00", // 600
    button_primary: "#FFD180", // A100
  },
  {
    // DEEP ORANGE
    accent_1: "#DD2C00",
    accent_2: "#F4511E",
    background_accent: "#FFCCBC",
    background_dark: "#FBE9E7",
    background_nav: "#FFAB91",
    button_primary: "#FF9E80",
    id: "#FF5722",
  },
  {
    //BROWN
    accent_1: "#8D6E63",
    accent_2: "#6D4C41",
    background_accent: "#D7CCC8",
    background_dark: "#EFEBE9",
    background_nav: "#BCAAA4",
    button_primary: "#795548",
    id: "#795548",
  },
  {
    // GRAY
    accent_1: "#BDBDBD",
    accent_2: "#757575",
    background_accent: "#F5F5F5",
    background_dark: "#FAFAFA",
    background_nav: "#EEEEEE",
    button_primary: "#9E9E9E",
    id: "#9E9E9E",
  },
  {
    // BLUE GRAY
    accent_1: "#78909C",
    accent_2: "#546E7A",
    background_accent: "#CFD8DC",
    background_dark: "#ECEFF1",
    background_nav: "#B0BEC5",
    button_primary: "#607D8B",
    id: "#607D8B",
  },
]
