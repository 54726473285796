import React from "react"
import './SelectorNavLeft.scss'
import AniLink from "gatsby-plugin-transition-link/AniLink";

function SelectorNavLeft(props) {
    return (
        <AniLink swipe direction="right" to={props.linkToPage}>
            <div className='nav-left-container'>
                <div className='title'>Tutoring</div>
            </div>
        </AniLink>
    )
}
export default SelectorNavLeft;
